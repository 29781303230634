import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Media } from '../models';
import { PagedOverview } from 'src/app/standalone/models';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  UploadFile(file: File, overwrite: boolean = false) {
    const formData: FormData = new FormData();
    formData.append('File', file);

    return this.http.put<any>(`${this.BASE_URL}api/media/upload?overwrite=${overwrite}`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  Exists(filename: string) {
    return this.http.get<boolean>(`${this.BASE_URL}api/media/exists?filename=${filename}`);
  }

  getMedia(skip: number, take: number, q: string = '') {
    return this.http.get<PagedOverview<Media>>(`${this.BASE_URL}api/media?take=${take}&skip=${skip}&q=${q}`);
  }

  deleteMedia(ids: number[]) {
    return this.http.delete<Media[]>(`${this.BASE_URL}api/media?ids=${ids.join('&ids=')}`);
  }

  ExportToZip(ids: number[]) {
    return this.http.get<Blob>(`${this.BASE_URL}api/media/archive?ids=${ids.join('&ids=')}`, { responseType: 'blob' as 'json' });
  }
}
