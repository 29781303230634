import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromMedia from '../reducers/media.reducer';
import { Media } from '../../models';

export const getMediasState = createSelector(fromFeature.getMediaState, (state: fromFeature.MediaState) => state.media);

export const getMediaEntities = createSelector(getMediasState, fromMedia.getMediaEntities);
export const getMediaPage = createSelector(getMediasState, fromMedia.getMediaPage);
export const getMediaLoaded = createSelector(getMediasState, fromMedia.getMediaLoaded);
export const getMediaLoading = createSelector(getMediasState, fromMedia.getMediaLoading);

export const getAllMedia = createSelector(getMediaEntities, entities => {
  return Object.keys(entities).map((id: string) => entities[+id]);
});

export const getOrderedMedia = createSelector(getAllMedia, media => {
  return media.sort((a, b) => new Date(b.uploadedOn).getTime() - new Date(a.uploadedOn).getTime());
});

export const getGroupedMedia = createSelector(getOrderedMedia, mediaList => {
  const grouped = mediaList.reduce(
    (acc, media) => {
      const date = new Date(media.uploadedOn);
      const key = date.toDateString();
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(media);
      return acc;
    },
    {} as Record<string, Media[]>
  );
  return Object.entries(grouped).map(([key, value]) => ({ key, value }));
});
