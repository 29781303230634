import { inject } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as actions from '../actions/uploads.action';
import { switchMap, catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MediaService } from '../../services';
import { HttpEventType } from '@angular/common/http';

export const uploadFile$ = createEffect(
  (actions$ = inject(Actions), service = inject(MediaService)) => {
    return actions$.pipe(
      ofType(actions.UploadFile),
      mergeMap(({ file, overwrite }) => {
        return service.UploadFile(file, overwrite).pipe(
          map(e => {
            switch (e.type) {
              case HttpEventType.Response:
                return actions.UploadFileSuccess({ file, media: e.body });
              case HttpEventType.UploadProgress:
                return actions.UploadFileProgress({ event: e, file });
              default:
                return actions.UploadFileEvent({ event: e });
            }
          }),
          catchError(error => of(actions.UploadFileFail({ error, file })))
        );
      })
    );
  },
  { functional: true }
);
