import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/records.action';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { TablesService } from '../../services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';

export const LoadRecords$ = createEffect(
  (actions$ = inject(Actions), service = inject(TablesService)) => {
    return actions$.pipe(
      ofType(actions.LoadRecords),
      switchMap(({ name }) =>
        service.getRecords(name).pipe(
          map(records => actions.LoadRecordsSuccess({ name, records })),
          catchError(error => of(actions.LoadRecordsFail({ name, error })))
        )
      )
    );
  },
  { functional: true }
);

export const DeleteRecords$ = createEffect(
  (actions$ = inject(Actions), service = inject(TablesService)) => {
    return actions$.pipe(
      ofType(actions.DeleteRecords),
      switchMap(({ name, ids }) =>
        service.deleteRecords(name, ids).pipe(
          map(records => actions.DeleteRecordsSuccess({ name, ids })),
          catchError(error => of(actions.DeleteRecordsFail({ name, error })))
        )
      )
    );
  },
  { functional: true }
);

export const DeleteRecordsSuccess$ = createEffect(
  (actions$ = inject(Actions), sb = inject(MatSnackBar), lang = inject(LanguageService)) => {
    const dict = new FromDictionaryPipe(lang);
    return actions$.pipe(
      ofType(actions.DeleteRecordsSuccess),
      tap(() => sb.open(dict.transform('form:verwijderen-geslaagd'), dict.transform('form:verwijderen-geslaagd-knop'), { duration: 2000 }))
    );
  },
  { functional: true, dispatch: false }
);
