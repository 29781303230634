import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SqlRecord, SqlTable, TableConfiguration, TablePreferences } from '../models';

@Injectable({
  providedIn: 'root',
})
export class TablesService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getTable(name: string) {
    return this.http.get<SqlTable>(`${this.BASE_URL}api/tables/${name}`);
  }

  getTablePreferences(name: string) {
    return this.http.get<TablePreferences>(`${this.BASE_URL}api/tables/${name}/preferences`);
  }

  updateTablePreferences(name: string, preferences: TablePreferences) {
    return this.http.post<TablePreferences>(`${this.BASE_URL}api/tables/${name}/preferences`, { preferences: JSON.stringify(preferences) });
  }

  getTableConfiguration(name: string) {
    return this.http.get<TableConfiguration>(`${this.BASE_URL}api/tables/${name}/configuration`);
  }

  getRecords(name: string, take: number | null = null, q: string = '', titleField: string = '') {
    return this.http.get<SqlRecord[]>(`${this.BASE_URL}api/tables/${name}/records?take=${take || ''}&q=${q}&titleField=${titleField}`);
  }

  getRecord(name: string, id: number | string) {
    return this.http.get<SqlRecord>(`${this.BASE_URL}api/tables/${name}/records/${id}`);
  }

  getRelatedData(table: string, titleField: string = '', q: string = '') {
    return this.http.get<{ id: number | string; title: string }[]>(`/api/tables/${table}/records/find?titleField=${titleField}&q=${q}`);
  }

  saveRecord(name: string, id: number | string, data: { [field: string]: any }) {
    return this.http.post<SqlRecord>(`${this.BASE_URL}api/tables/${name}/records/${id}`, data);
  }

  duplicateRecord(name: string, id: number | string) {
    return this.http.post(`${this.BASE_URL}api/tables/${name}/records/${id}/duplicate`, null);
  }

  deleteRecord(name: string, id: number | string) {
    return this.http.delete(`${this.BASE_URL}api/tables/${name}/records/${id}`);
  }

  deleteRecords(name: string, ids: (number | string)[]) {
    return this.http.delete(`${this.BASE_URL}api/tables/${name}/records?ids=${ids.join('&ids=')}`);
  }

  ExportToExcel(name: string, ids: (number | string)[]) {
    return this.http.get<Blob>(`${this.BASE_URL}api/excel/${name}?ids=${ids.join('&ids=')}`, { responseType: 'blob' as 'json' });
  }
}
