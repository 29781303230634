import { createAction, props } from '@ngrx/store';
import { Media } from '../../models';
import { PagedOverview } from 'src/app/standalone/models';

export const LoadMedia = createAction('[Media] Load Media', props<{ skip: number; take: number; q?: string }>());
export const LoadMediaSuccess = createAction('[Media] Load Media Success', props<{ media: PagedOverview<Media> }>());
export const LoadMediaFail = createAction('[Media] Load Media Fail', props<{ error: any }>());

export const LoadMoreMedia = createAction('[Media] Load More Media');
export const LoadMoreMediaSuccess = createAction('[Media] Load More Media Success', props<{ media: PagedOverview<Media> }>());
export const LoadMoreMediaFail = createAction('[Media] Load More Media Fail', props<{ error: any }>());

export const DeleteMedia = createAction('[Media] Delete Media', props<{ ids: number[] }>());
export const DeleteMediaSuccess = createAction('[Media] Delete Media Success', props<{ items: Media[] }>());
export const DeleteMediaFail = createAction('[Media] Delete Media Fail', props<{ error: any }>());
