import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/media.action';
import { Media } from '../../models';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import { PageData } from 'src/app/standalone/models';

export interface MediaState {
  entities: { [id: number]: Media };
  page: PageData;
  loading: boolean;
  loaded: boolean;
  error?: any;
}

export const initialState: MediaState = {
  entities: {},
  page: { currentPage: 0, pageCount: 0, pageSize: 50, resultCount: 0, filters: {} },
  loading: false,
  loaded: false,
};

export const MediaReducer = createReducer(
  initialState,
  on(actions.LoadMedia, actions.LoadMoreMedia, (state: MediaState) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(actions.LoadMediaSuccess, (state: MediaState, { media }) => {
    const entities = ToEntities(media.items, 'id', {});
    return {
      ...state,
      entities,
      page: media.page,
      loading: false,
      loaded: true,
    };
  }),
  on(actions.LoadMoreMediaSuccess, (state: MediaState, { media }) => {
    const entities = ToEntities(media.items, 'id', state.entities);
    return {
      ...state,
      entities,
      page: media.page,
      loading: false,
      loaded: true,
    };
  }),
  on(actions.LoadMediaFail, actions.LoadMoreMediaFail, (state: MediaState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  }),
  on(actions.DeleteMedia, (state: MediaState) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(actions.DeleteMediaSuccess, (state: MediaState, { items }) => {
    const entities = { ...state.entities };
    items.forEach(item => delete entities[item.id || item.Id]);

    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(actions.DeleteMediaFail, (state: MediaState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getMediaEntities = (state: MediaState) => state.entities;
export const getMediaPage = (state: MediaState) => state.page;
export const getMediaLoading = (state: MediaState) => state.loading;
export const getMediaLoaded = (state: MediaState) => state.loaded;
