import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromRecords from '../reducers/record-detail.reducer';
import * as fromRoot from '../../../store';
import { SqlRecord } from '../../models';

export const getRecordDetailState = createSelector(fromFeature.getContentState, (state: fromFeature.ContentState) => state.recordDetail);

export const getRecordDetail = createSelector(getRecordDetailState, fromRecords.getRecordDetail);
export const getRecordDetailTable = createSelector(getRecordDetailState, fromRecords.getRecordDetailTable);
export const getRecordDetailLoaded = createSelector(getRecordDetailState, fromRecords.getRecordDetailLoaded);
export const getRecordDetailLoading = createSelector(getRecordDetailState, fromRecords.getRecordDetailLoading);

export const getSelectedRecordDetailLoading = createSelector(
  getRecordDetailLoading,
  getRecordDetailTable,
  fromRoot.getRouterState,
  (loading, table, router): boolean => {
    return (
      router.state &&
      loading === (router.state.params?.['id'] || router.state.data?.['id']) &&
      table === (router.state.params?.['table'] || router.state.data?.['table'])
    );
  }
);

export const getSelectedRecordDetailLoaded = createSelector(
  getRecordDetailLoaded,
  getRecordDetailTable,
  fromRoot.getRouterState,
  (loaded, table, router): boolean => {
    return (
      router.state &&
      loaded == (router.state.params?.['id'] || router.state.data?.['id']) &&
      table === (router.state.params?.['table'] || router.state.data?.['table'])
    );
  }
);

export const getRecordDetailUpdateError = createSelector(getRecordDetailState, fromRecords.getRecordDetailUpdateError);
