import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MediaService } from '../../services';
import * as MediaActions from '../actions';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { getMediaPage } from '../selectors';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';

export const loadMedia$ = createEffect(
  (actions$ = inject(Actions), service = inject(MediaService)) => {
    return actions$.pipe(
      ofType(MediaActions.LoadMedia),
      switchMap(({ skip, take, q }) =>
        service.getMedia(skip, take, q).pipe(
          map(media => MediaActions.LoadMediaSuccess({ media })),
          catchError(error => of(MediaActions.LoadMediaFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const loadMoreMedia$ = createEffect(
  (actions$ = inject(Actions), service = inject(MediaService), store = inject(Store)) => {
    return actions$.pipe(
      ofType(MediaActions.LoadMoreMedia),
      withLatestFrom(store.select(getMediaPage)),
      switchMap(([{}, page]) =>
        service.getMedia((page.currentPage + 1) * page.pageSize, page.pageSize, page.filters?.['q'] || '').pipe(
          map(media => MediaActions.LoadMoreMediaSuccess({ media })),
          catchError(error => of(MediaActions.LoadMoreMediaFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const deleteMedia$ = createEffect(
  (actions$ = inject(Actions), service = inject(MediaService)) => {
    return actions$.pipe(
      ofType(MediaActions.DeleteMedia),
      switchMap(({ ids }) =>
        service.deleteMedia(ids).pipe(
          map(items => MediaActions.DeleteMediaSuccess({ items })),
          catchError(error => of(MediaActions.DeleteMediaFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const deleteMediaSuccess$ = createEffect(
  (actions$ = inject(Actions), sb = inject(MatSnackBar), lang = inject(LanguageService)) => {
    const dict = new FromDictionaryPipe(lang);
    return actions$.pipe(
      ofType(MediaActions.DeleteMediaSuccess),
      tap(() => sb.open(dict.transform('form:verwijderen-geslaagd'), dict.transform('form:verwijderen-geslaagd-knop'), { duration: 2000 }))
    );
  },
  { functional: true, dispatch: false }
);
